// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
const BACKEND_BASE_URL = "https://edelegateecs.dev.un.int";
const BACKEND_WS_BASE_URL = "wss://edelegateecs.dev.un.int";
const USER_POOL_ID = "us-east-1_CgY0AqghF";
const CLIENT_ID = "54ilos11lchnpmfevlk47fv3md";
const CALLBACK_URL = "https://edelegate.dev.un.int";
const SIGNOUT_URL = "https://edelegate.dev.un.int";
const APP_WEB_DOMAIN = "edelegatedev.auth.us-east-1.amazoncognito.com";
export const environment = {
  production: false,
  usersApi: BACKEND_BASE_URL + "/users",
  countriesApi: BACKEND_BASE_URL + "/countries",
  announcementsApi: BACKEND_BASE_URL + "/announcements",
  committeeplaceApi: BACKEND_BASE_URL + "/committeeplace",
  listOfMeetingsApi: BACKEND_BASE_URL + "/listofmeetings",
  eDecisionsApi: BACKEND_BASE_URL + "/edecisions",
  inscriptionMeetingsApi: BACKEND_BASE_URL + "/inscriptionmeetings",
  eListOfSpeakersApi: BACKEND_BASE_URL + "/elistofspeakers",
  lookupsApi: BACKEND_BASE_URL + "/lookups",
  eregistrationApi: BACKEND_BASE_URL + "/eregistration",
  meetingsparticipantsApi: BACKEND_BASE_URL + "/listofparticipants",
  delegatesApi: BACKEND_BASE_URL + "/delegates",
  securityCouncilApi: BACKEND_BASE_URL + "/securitycouncil",
  ecredentialsApi: BACKEND_BASE_URL + "/credentials",
  pDelegateApi: BACKEND_BASE_URL + "/pdelegate",
  tempdecaldriverrequestsApi: BACKEND_BASE_URL + "/tempdecaldriverrequests",
  eSpeakersApi: BACKEND_BASE_URL + "/espeakers",
  ePublishedListApi: "http://espeakers.dev.un.int",
  votesApi: BACKEND_BASE_URL + "/votes",
  sponsorshipApi: BACKEND_BASE_URL + "/sponsorship",
  ballotApi: BACKEND_BASE_URL + "/ballot",
  integrationApi: BACKEND_BASE_URL + "/integration",
  meetingProceedingsApi: BACKEND_BASE_URL + "/meetingproceedings",
  thirdCommitteeApi: BACKEND_BASE_URL + "/thirdcommittee",
  placeCpcApi: BACKEND_BASE_URL + "/placecpc",
  placeCocApi: BACKEND_BASE_URL + "/placecoc",
  placeC34Api: BACKEND_BASE_URL + "/placec34",
  placeC24Api: BACKEND_BASE_URL + "/placec24",
  placeDcApi: BACKEND_BASE_URL + "/placedc",
  placeOewgcsApi: BACKEND_BASE_URL + "/placeoewgcs",
  fourthCommitteeApi: BACKEND_BASE_URL + "/fourthcommittee",
  secondCommitteeApi: BACKEND_BASE_URL + "/secondcommittee",
  fifthCommitteeApi: BACKEND_BASE_URL + "/placec5",
  ecosocCommitteeApi: BACKEND_BASE_URL + "/ecosoccommittee",
  pfiiCommitteeApi: BACKEND_BASE_URL + "/place-pfii",
  placeCandiApi: BACKEND_BASE_URL + "/placecandi",
  placeSC1540Api: BACKEND_BASE_URL + "/placesc1540",
  placeSC1591Api: BACKEND_BASE_URL + "/placesc1591",
  placeSC2127Api: BACKEND_BASE_URL + "/placesc2127",
  placeSC1970Api: BACKEND_BASE_URL + "/placesc1970",
  placeSC1518Api: BACKEND_BASE_URL + "/placesc1518",
  placeSC1373Api: BACKEND_BASE_URL + "/placesc1373",
  placeSC1533Api: BACKEND_BASE_URL + "/placesc1533",
  placeSC751Api: BACKEND_BASE_URL + "/placesc751",
  placeSC1267Api: BACKEND_BASE_URL + "/placesc1267",
  placeSC1718Api: BACKEND_BASE_URL + "/placesc1718",
  placeSC2140Api: BACKEND_BASE_URL + "/placesc2140",
  placeSC2048Api: BACKEND_BASE_URL + "/placesc2048",
  placeSC1988Api: BACKEND_BASE_URL + "/placesc1988",
  placeSC2206Api: BACKEND_BASE_URL + "/placesc2206",
  placeSC2374Api: BACKEND_BASE_URL + "/placesc2374",
  placeSC2231Api: BACKEND_BASE_URL + "/placesc2231",
  placeSC2653Api: BACKEND_BASE_URL + "/placesc2653",
  placeSCwgcaacApi: BACKEND_BASE_URL + "/placewgcaac",
  placeSCiwgApi: BACKEND_BASE_URL + "/placesciwg",
  webSocketApi: BACKEND_WS_BASE_URL + "/websocketscheduler",
  confBapaApi: BACKEND_BASE_URL + "/place-conferences",
  gaPlenaryApi: BACKEND_BASE_URL + "/place-gaplenary",
  documentsIssuedApi: "https://journalapi.un.org",
  placeOdaApi: BACKEND_BASE_URL + "/place-oda",
  notificationSchedulerApi: BACKEND_BASE_URL + "/notification-scheduler/api",
  candidaturesApi: BACKEND_BASE_URL + "/candidatures",
  delegatesInfoApi: BACKEND_BASE_URL + "/delegatesinfo",
  submitApi: BACKEND_BASE_URL + "/submit",
  proposalsApi: BACKEND_BASE_URL + "/proposals",
  proposalsmpmApi: BACKEND_BASE_URL + "/proposalsmpm",
  placeIndexerApi: BACKEND_BASE_URL + "/place-indexer",
  // AWS Cognito variables
  region: "us-east-1",
  identityPoolId: "",
  userPoolId: USER_POOL_ID,
  provider: "uniteid",
  clientId: CLIENT_ID,
  azureProvider: "azuread",
  callbackURL: CALLBACK_URL,
  signoutURL: SIGNOUT_URL,
  appWebDomain: APP_WEB_DOMAIN,
  rekognitionBucket: "rekognition-pics",
  albumName: "usercontent",
  bucketRegion: "us-east-1",
  ddbTableName: "LoginTrail",
  cognito_idp_endpoint: "",
  cognito_identity_endpoint: "",
  sts_endpoint: "",
  dynamodb_endpoint: "",
  s3_endpoint: "",
  branch: "DEV",
  version: "2025.01.10D-12:00",
};
